import React from 'react';
import Layout from '../../components/layout';
import Questions from '../../components/Questions';

const AmbulanteOperationenUndDermatologischeTumorchirurgie = ({
	data,
	location,
	...props
}) => {
	return (
		<Layout
			location={location}
			title="Ambulante Operationen und dermatologische Tumorchirurgie"
			noSlider
			{...props}
		>
			<p>
				In der Hautkrebsvorsorge auffallende Veränderungen sollen durch
				die Entnahme einer Gewebeprobe identifiziert werden. Andere
				Hautveränderungen gehören möglichst schnell komplett entfernt.
				Wir begleiten Sie von der Erstuntersuchung bis zum Abschluss der
				Behandlung mit Einfühlvermögen, verständlicher Aufklärung und
				professionellem medizinischem Geschick und medizinischer
				Technik.
			</p>
			<p>
				Im Anschluss an den ambulanten operativen Eingriff werden Sie
				über das postoperative Verhalten informiert und bekommen einen
				auf Ihren Eingriff zugeschnittenen Empfehlungsbogen mit nach
				Hause.
			</p>
			<p>
				Sehen Sie einem ambulanten operativen Eingriff bei uns locker
				entgegen. Wir stehen an ihrer Seite, arbeiten schnell und
				fachlich auf einem sehr hohen Niveau.
			</p>

			<Questions title="ambulanten Operationen und dermatologische Tumorchirurgie" />
		</Layout>
	);
};

export default AmbulanteOperationenUndDermatologischeTumorchirurgie;
